export default {
    methods:{
        b64toBlob(b64Data, contentType, sliceSize){
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        base64toBlob (base64Data, contentType) {
            contentType = contentType || ''
            const sliceSize = 1024
            const byteCharacters = window.atob(base64Data)
            const bytesLength = byteCharacters.length
            const slicesCount = Math.ceil(bytesLength / sliceSize)
            const byteArrays = new Array(slicesCount)
      
            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize
              const end = Math.min(begin + sliceSize, bytesLength)
      
              const bytes = new Array(end - begin)
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0)
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes)
            }
            return new Blob(byteArrays, { type: contentType })
          }
    }

}