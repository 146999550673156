<template>
  <v-flex lg12>
    <v-card>
      <v-toolbar color="grey lighten-3" class=" elevation-0">
        <div class="text-h6 font-weight-bold py-3">
          Lista de Certificaciones de Título de {{nombreProcedencia}}
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="getCertificaciones" icon color="primary" class="mx-3">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" rounded  @click="dialogNuevo=true">
            <v-icon class="pr-2">mdi-plus</v-icon> AGREGAR
        </v-btn>
        <Nuevo 
            v-if="dialogNuevo"
            :dialog="dialogNuevo"
            @cancelar="cancelar"
            :value="selected"
        />
      </v-toolbar>
      <v-card-text>  
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-0"
          :loading="loading"
        >
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.estado == 'POR APROBAR'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      color="orange"
                      icon
                      @click="modificarCertificacion(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Modificar Certificacion</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      color="primary"
                      icon
                      @click="getPdf(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-file-certificate </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver Certificacion</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.estado == 'POR APROBAR'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      icon
                      @click="iniciarCancelacion(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                 </template>
                  <span>Cancelar Certificacion</span>
                </v-tooltip>
            </template>
            <template v-slot:item.estado="{ item }">
                <v-chip :color="item.estado=='CONCLUIDO'?'success':'error'">{{item.estado}}</v-chip>
            </template>
            <template v-slot:item.fechaHojaRuta="{ item }">
                {{formatDateGlobal(item.fechaHojaRuta)}}
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="90vh">
          <v-card v-if="certificacion.firma">
            <v-card-title class="subtitle-2 grey lighten-2">
              Certificacion Aprobada Con Ciudadanía Digital
            </v-card-title>
            <v-card-text class="pt-4 pb-0">
              <div id="previewDocumento"></div>
              <v-row class="pt-5">
                <v-col cols="6" class="text-center">
                  <span class="font-weight-medium"  color="primary">Usuario que solicito la firma de el documento:</span><br>
                   {{certificacion.firma.usuario}}
                </v-col>
                <v-col cols="6" class="text-center" v-if="selected.estado!='CANCELADO' && certificacion.firma.fecha">
                  <span class="font-weight-medium" color="primary">Fecha de firma:</span><br>
                  {{formatDateGlobal(certificacion.firma.fecha)}}
                </v-col>
                <v-col v-if="selected.estado!='CANCELADO' && !certificacion.firma.fecha" cols="6" class="text-center">
                  <v-chip color="danger">PENDIENTE</v-chip>
                  <br/>
                  <v-btn 
                    v-if="!certificacion.firma.aceptado"
                    color="primary"
                    class="mt-3"
                    rounded
                    @click="aprobarDocumento(certificacion.firma.link)"
                  >
                    <v-icon>mdi-draw</v-icon> APROBAR DOCUMENTO
                  </v-btn>

                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="cancelar"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogCancelar"
          persistent
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="grey lighten-2" class="text-h6" flat>
              <v-icon color="orange" large class="pr-2">mdi-alert</v-icon> Confirmación
            </v-toolbar>
            <v-card-text class="pt-3 pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-form v-model="valid" lazy-validation ref="formCancelacion">
                    <p class="text-body- black--text">¿Está seguro de cancelar la solicitud de certificacion de titulo digital con cite {{selected.cite}}?</p>
                    <v-textarea
                      filled
                      name="observacion"
                      label="Observacion"
                      focus
                      v-model="observacion"
                      :rules="rulesObservacion"
                      auto-grow
                      rows="2"
                    />
                  </v-form>
                </v-col>
                <v-col cols="12">
                  
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="error" text @click="cancelar">CANCELAR</v-btn>
              <v-btn color="primary" @click="cancelarRegistro">Continuar</v-btn>
            </v-card-actions>
          </v-card>
          
        </v-dialog>

      </v-card-text>
    </v-card>
  </v-flex>
</template>
<script>
import { mapState } from 'vuex';
import PDFObject from "@/../paquetes_externos/pdfobject.min";
import mixinB64toBlob from "@/mixins/b64toblob"

export default {
  components:{
      Nuevo: ()=>import('@/views/RegistroCertificacionTitulo/Nuevo')
  },
  mixins: [mixinB64toBlob],
  data() {
    return {
      url: "abogado-ciudadano/certificacion-titulo",
      dialogNuevo: false,
      certificacion:{
        firma:null
      },
      headers: [
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
          selected: true
        },
        
        {
          text: "Nro Documento",
          align: "center",
          value: "hojaRuta",
          sortable: false,
          selected: true
        },
        {
          text: "Fecha Documento",
          align: "center",
          value: "fechaHojaRuta",
          selected: true
        },
        {
          text: "Estado",
          align: "center",
          value: "estado",
          selected: true
        },
        {
          text: "Cantidad Abogados",
          align: "center",
          value: "cantidad",
          sortable: false,
          selected: true
        }
      ],
      items:[],
      fileCsv: null,
      imageName: "",
      dialog:false,
      loading:false,
      dialogCancelar:false,
      observacion:null,
      valid:true,
      rulesObservacion:[
        v=>!!v||'El campo es requerido',
        v=>!!v&&v.length>50 || 'Cantidad minima 50 caracteres'
      ],
      selected:{}
    };
  },
  computed:{
      ...mapState({
            nombreProcedencia: state => state.session.user.nombreUniversidad
        })
  },
  async created(){
      await this.getCertificaciones()
  },
  methods: {
    getCertificaciones(){
        this.items=[]
        this.loading=true
        this.$http.get(`${this.$apiUrl}${this.url}`).then(response=>{
            this.items=response.data.data.items
            this.loading=false
        })
    },
    formatDateGlobal(date) {
      if (!date) return null;
      const [dates, hours] = date.split(" ");
      if (date.indexOf("-") > 0) {
        const [year, month, day] = dates.split("-");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
    getPdf(item){
      this.selected = {...item}
      this.$http.get(`${this.$apiUrl}${this.url}/${item.id}/pdf`).then(response=>{
        this.certificacion = response.data.data
          setTimeout(() => {
            this.showDocumento(response.data.data.pdf,'#previewDocumento',true);
          }, 100);
          this.dialog=true
        })
    },
    showDocumento(pdf,id, base64) {
      const options = {
          height: "400px",
          pdfOpenParams: {
          pagemode: "thumbs",
          navpanes: 0,
          toolbar: 1,
          statusbar: 1,
          view: "FitH"
          }
      };
      if(base64){
        pdf='data:application/pdf;base64,'+pdf
      }
      PDFObject.embed(pdf, id, options);
    },
    modificarCertificacion(certificacion){
      this.selected={...certificacion}
      this.dialogNuevo =true
    },
    iniciarCancelacion(certificacion){
      this.selected = {...certificacion}
      this.dialogCancelar=true
    },
    cancelarRegistro (){
      if(this.selected.id && this.$refs.formCancelacion.validate()){
        this.$http.patch(`${this.$apiUrl}${this.url}/${this.selected.id}/cancelar`,{
          observacion:this.observacion
        }).then(async response=>{
          if(response){
            await this.getCertificaciones()
            this.cancelar()
            
          }
        })
      }
    },
    cancelar(){
      this.selected = {}
      this.dialogNuevo = false
      this.dialogCancelar = false
      this.dialog = false
      this.observacion=null
    },
    aprobarDocumento(link){
      window.location.href = link
    }
  }
};
</script>
